import { navigate } from "gatsby"

import { getSignedInUser } from "../../Auth/services/user"

import {
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
} from "gatsby-env-variables"

export const getTableOptions = () => {
  const { userData } = getSignedInUser()

  return {
    customToolbarSelect: () => {},
    onRowClick: (data) => {
      switch (userData.role) {
        case GATSBY_MEDSTAFF_ROLE_ID:
          navigate("edit")
          break
        case GATSBY_MHP_ROLE_ID:
        default:
          navigate(`/patients/profile?patientId=${data[0].data}`)
          break
      }
    },
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    customSearch: (searchQuery, currentRow) => {
      let isFound = false
      currentRow.forEach((col) => {
        if (
          col &&
          col.data
            .toString()
            .toLowerCase()
            .replace(/\s/g, "")
            .indexOf(searchQuery.toLowerCase().replace(/\s/g, "")) >= 0
        ) {
          isFound = true
        }
      })
      return isFound
    },
  }
}
