import React, { useState, useEffect } from "react"

import Datatable from "../Elements/Datatable"
import DashboardLayout from "layout/DashboardLayout"
import { columns } from "./utils/table"
import { getTableOptions } from "./services/getTableOptions"
import { getPatientsData } from "./services/getPatients"

const Patients = ({ pageContext }) => {
  const [patients, setPatients] = useState([])

  let { module } = pageContext

  const getPatients = () => {
    let results = getPatientsData()
    setPatients(results)
  }

  useEffect(() => {
    // dispatch({ type: "RESET_BOOKING" })
    sessionStorage.setItem("activeConsultations", [])
    sessionStorage.setItem("selectedPatient", null)
    getPatients()
  }, [])

  return (
    <DashboardLayout
      seoTitle={module.title}
      title={module.title}
      pageContext={pageContext}
    >
      <Datatable
        data={patients}
        options={getTableOptions()}
        columns={columns}
        defaultSortField={"employeeName"}
        defaultSortDirection={"asc"}
      />
    </DashboardLayout>
  )
}

export default Patients
