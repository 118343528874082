//should be the same as table config

//should be getTableConfig.js

export const columns = [
  {
    name: "Email",
    options: {
      display: false,
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Employee ID",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Employee Name",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
    selector: "employeeName",
    sortable: true,
  },
  {
    name: "Account",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "Department",
    options: {
      filter: true,
      setCellHeaderProps: (value) => ({
        style: { fontWeight: "bold" },
      }),
    },
  },
  {
    name: "",
    options: {
      sort: false,
    },
  },
]
