import { getSignedInUser } from "auth/services/user"

export const getPatientsData = () => {
  // const renderViewProfileButton = (selectedPatientEmail) => {
  //   return (
  //     <div className="buttons">
  //       <Button
  //         size="small"
  //         color="primary"
  //         width="120px"
  //         onClick={() => {
  //           getSelectedPatient(selectedPatientEmail)
  //           navigate("edit")
  //         }}
  //       >
  //         Edit Profile
  //       </Button>
  //     </div>
  //   )
  // }

  return getSignedInUser().patients.map((patient) => {
    return [
      { id: true, type: "string", data: patient?.id },
      { type: "string", data: patient?.employeeNumber || "N/A" },
      {
        type: "string",
        data: patient?.lastName + ", " + patient?.firstName || "N/A",
      },
      { type: "string", data: patient?.company || "N/A" },
      { type: "string", data: patient?.department || "N/A" },
    ]
  })
}
